
import {defineComponent, ref, watch} from "vue";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "ReserveOutstanding",
  components: {CurrencyFormat},
  props: {
    claimId: {}
  },
  setup(props) {
    const value = ref(0);
    const load = (id) => {
      ClaimService.reserveOutstanding(id).then(res => {
        value.value = res.ro;
      })
    }
    load(props.claimId);
    watch(() => props.claimId, cb => {
      load(cb);
    })
    return {
      load,
      value,
    }
  }
})
