import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_ReserveLine = _resolveComponent("ReserveLine")!

  return (_ctx.entityLoading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_ReserveLine, {
          lines: _ctx.claim?.reserve?.lines
        }, null, 8, ["lines"]),
        _createElementVNode("div", {
          innerHTML: _ctx.claim?.reserve?.comment
        }, null, 8, _hoisted_1)
      ], 64))
}