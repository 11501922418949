
import {computed, defineComponent, onMounted, onUpdated, ref, watch} from "vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import CaseReserve from "@/views/reserve/CaseReserve.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ReserveForm from "@/views/reserve/ReserveForm.vue";
import {RESERVE_LINES} from "@/core/config/Constant";
import {Actions} from "@/store/enums/StoreEnums";
import store from "../../store";
import ReserveTimeLines from "@/views/reserve/ReserveTimeLines.vue";
import ClaimReserve from "@/views/reserve/ClaimReserve.vue";
import Swal from "sweetalert2";
import ReserveService from "@/core/services/ReserveService";
import VisualEmbed from "@/views/quicksight/VisualEmbed.vue";
import IdService from "@/core/services/IdService";
import CurrencyFormat from "@/components/base/currency/CurrencyFormat.vue";
import Row from "@/components/base/common/Row.vue";
import ReserveOutstanding from "@/views/claim/ReserveOutstanding.vue";

export default defineComponent({
  name: "Reserve",
  components: {
    ReserveOutstanding,
    Row,
    CurrencyFormat,
    VisualEmbed, ClaimReserve, ReserveTimeLines, ReserveForm, BaseModal, CaseReserve, QuickAction
  },
  props: {
    objectId: {type: String, required: true},
    objectType: {type: String, required: true}
  },
  setup(props) {
    const decodeValue = ref({value: NaN, decoded: false})

    const isEdit = ref(false);
    const reserveId = ref('');
    const currentReserve = computed(() => store.state.ReserveModule.currentReserve);
    const access = computed(() => store.state.ReserveModule.access);
    const reserveModel = ref({cazeId: '', claimId: '', comment: '', lines: RESERVE_LINES})
    const getId = (raw) => {
      IdService.decode(raw).then(res => {
        decodeValue.value.value = res;
      }).finally(() => {
        decodeValue.value.decoded = true
      })
    }
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      console.log(link);
      if (link) {
        link.classList.remove("active")
      }
    })
    onMounted(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    getId(props.objectId);
    watch(() => props.objectId, cb => {
      getId(cb)
    })
    return {
      decodeValue,
      access,
      reserveModel,
      store,
      RESERVE_LINES,
      isEdit,
      currentReserve,
      reserveId,
    }
  },
  methods: {
    onReserveEdit(reserve) {
      if (this.objectType === 'CASE') {
        this.reserveModel.cazeId = this.objectId
        this.reserveModel.claimId = ''
      } else {
        this.reserveModel.claimId = this.objectId
        this.reserveModel.cazeId = ''
      }
      this.reserveModel.lines = JSON.parse(JSON.stringify(this.currentReserve.lines));
      this.isEdit = true
      this.reserveId = reserve.id;
      this.reserveModel.comment = this.currentReserve.comment
      const baseModal = this.$refs.formReserveRef as typeof BaseModal
      baseModal.showBaseModal()
    },

    onWaiveReserve(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to remove the reserve from the case?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ReserveService.waive(id).then(() => {
            if (this.objectType === 'CASE') {
              this.store.dispatch(Actions.LOAD_CASE, this.objectId);
            }
            if (this.objectType === 'CLAIM') {
              this.store.dispatch(Actions.LOAD_CLAIM, this.objectId);
            }
            const reserveTimelines = this.$refs.reserveTimeLinesRef as typeof ReserveTimeLines
            reserveTimelines.paginationLoad();
          })
        }
      })
    },
    onReserveUpdate() {
      if (this.objectType === 'CASE') {
        this.reserveModel.cazeId = this.objectId
        this.reserveModel.claimId = ''
      } else {
        this.reserveModel.claimId = this.objectId
        this.reserveModel.cazeId = ''
      }
      this.isEdit = false;
      this.reserveId = ''
      this.reserveModel.lines = this.currentReserve ? JSON.parse(JSON.stringify(this.currentReserve.lines)) : [{
        type: '',
        currency: 'CAD',
        value: 0.0,
        valueCAD: 0.0
      }]
      this.reserveModel.comment = ''
      const baseModal = this.$refs.formReserveRef as typeof BaseModal
      baseModal.showBaseModal()
    },
    onSaved() {
      const baseModal = this.$refs.formReserveRef as typeof BaseModal
      baseModal.hideBaseModal()
      if (this.objectType === 'CASE') {
        this.store.dispatch(Actions.LOAD_CASE, this.objectId);
      }
      if (this.objectType === 'CLAIM') {
        this.store.dispatch(Actions.LOAD_CLAIM, this.objectId);
      }
      const reserveTimelines = this.$refs.reserveTimeLinesRef as typeof ReserveTimeLines
      reserveTimelines.paginationLoad();
    }
  }
})
