
import {defineComponent, onMounted, ref, watch} from "vue";
import QuicksightService from "@/core/services/QuicksightService";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import {createEmbeddingContext} from "amazon-quicksight-embedding-sdk";
import {generateString} from "@/core/helpers/functions";

export default defineComponent({
  name: "VisualEmbed",
  components: {EntityLoading},
  props: {
    elementId: {type: String, required: true},
    dashboardId: {type: String, required: true},
    sheetId: {type: String, required: true},
    visualId: {type: String, required: true},
    title: {type: String, required: true},
    height: {type: Number, default: () => 500},
    params: {
      required: false, default: () => {
      }
    }
  },
  setup(props) {
    const loading = ref(true)

    const load = async () => {
      const embeddingContext = await createEmbeddingContext({})
      loading.value = true;
      await QuicksightService.visual(props.dashboardId, props.sheetId, props.visualId).then(async (res) => {
        const frameOptions: any = {
          // url: res.url,// replace this value with the url generated via embedding API
          url: res.url + generateString(props.params),// replace this value with the url generated via embedding API
          container: "#" + props.elementId,
          height: props.height + "px",
        };
        const contentOptions = {
          locale: "en-US",
        };
        await embeddingContext.embedVisual(frameOptions, contentOptions);
      }).finally(() => {
        loading.value = false;
      })
    }

    onMounted(async () => {
      await load();
    })

    watch(() => [props.dashboardId, props.visualId, props.sheetId], async () => {
      await load()
    })
    const refresh = () => {
      const element = document.getElementById(props.elementId + 'p');
      console.log(element);
      if (element) {
        const child = document.getElementById(props.elementId);
        if (child) {
          element.removeChild(child)
        }
        const node = document.createElement("div");
        node.id = props.elementId;
        element.appendChild(node);
      }
      load();
    }
    return {
      refresh,
      load,
      loading
    }
  }
})
