
import { computed, defineComponent, ref } from "vue";
import { LoadFilterObjects, LoadPanel } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "ReserveTimeLines",
  components: {Avatar, DateTimeFormat},
  props: {
    id: {type: String},
    type: {type: String}
  },
  setup(props) {
    const filterObject = ref({objectType: props.type, objectId: props.id})
    const timelinePage = computed(() => store.state.ReserveModule.timelinePage);
    return {
      ...LoadFilterObjects(Actions.LOAD_RESERVE_TIMELINES, filterObject.value, ['user']),
      filterObject,
      timelinePage,
      ...LoadPanel()
    }
  },
  watch: {
    id(cb) {
      this.filterObject.objectType = this.type
      this.filterObject.objectId = cb
      this.updateFilterObject(this.filterObject);
    },
    type(cb) {
      this.filterObject.objectType = cb
      this.filterObject.objectId = this.id
      this.updateFilterObject(this.filterObject);
    }
  }
})
