
import {computed, defineComponent} from "vue";
import store from "../../store";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import ReserveLine from "@/views/reserve/ReserveLine.vue";

export default defineComponent({
  name: "ClaimReserve",
  components: {ReserveLine, EntityLoading},
  setup() {
    const claim = computed(() => store.state.ClaimModule.claim);
    const entityLoading = computed(() => store.state.LoadingModule.entityLoading);
    return {
      entityLoading,
      claim,
    }
  }
})
