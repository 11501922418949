
import { computed, defineComponent } from "vue";
import store from "../../store";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import ReserveLine from "@/views/reserve/ReserveLine.vue";

export default defineComponent({
  name: "CaseReserve",
  components: {ReserveLine, EntityLoading},
  setup() {
    const caze = computed(() => store.state.CaseModule.case);
    const entityLoading = computed(() => store.state.LoadingModule.entityLoading);
    return {
      entityLoading,
      caze,
    }
  }
})
